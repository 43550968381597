<mat-drawer-container class="mat-drawer-container" autosize>
  <mat-drawer #drawer [opened]="true" class="sidenav" mode="side">
    <div class="logo-container">Platz für Logo</div>
    <ul class="menu-items">
      <li [routerLink]="['/']"><mat-icon>fact_check</mat-icon>Dashboard</li>
      <li [routerLink]="['user']"><mat-icon>perm_identity</mat-icon>User</li>
    </ul>
  </mat-drawer>
  <div class="example-sidenav-content">
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <mat-icon class="menu-icon" (click)="drawer.toggle()">menu</mat-icon>
        <span>Simple CRM</span>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="router-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-drawer-container>
