<mat-progress-bar *ngIf="loading" mode="indeterminate" value="40"></mat-progress-bar>
<h1 mat-dialog-title>Add User</h1>
<div mat-dialog-content class="pg-top">
  <div>
    <mat-form-field class="mr-16" appearance="outline">
      <mat-label>First Name</mat-label>
      <input [disabled]="loading" [(ngModel)]="user.firstName" matInput placeholder="Placeholder">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Last Name</mat-label>
      <input [disabled]="loading" [(ngModel)]="user.lastName" matInput placeholder="Placeholder" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Email</mat-label>
      <input [disabled]="loading" [(ngModel)]="user.email" matInput placeholder="Placeholder" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Birthday</mat-label>
      <input [disabled]="loading" [(ngModel)]="birthDate" matInput [matDatepicker]="picker">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Street + House No.</mat-label>
      <input [disabled]="loading" [(ngModel)]="user.street" matInput placeholder="Placeholder" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="mr-16">
      <mat-label>Zip Code</mat-label>
      <input [disabled]="loading" [(ngModel)]="user.zipCode" matInput placeholder="Placeholder" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>City</mat-label>
      <input [disabled]="loading" [(ngModel)]="user.city" matInput placeholder="Placeholder" />
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button [disabled]="loading" mat-button (click)="dialogRef.close()">Cancel</button>
  <button [disabled]="loading" mat-button (click)="saveUser()" cdkFocusInitial>Save</button>
</div>