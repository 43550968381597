<mat-card>
  <table class="user-table">
    <tr>
      <th>Name</th>
      <th>E-Mail</th>
      <th>City</th>
    </tr>
    <tr *ngFor="let user of allUsers" [routerLink]="['/user/' + user.id]">
      <td>{{ user.firstName }} {{ user.lastName }}</td>
      <td>{{ user.email }}</td>
      <td>{{ user.city }}</td>
    </tr>
  </table>
</mat-card>

<button class="add-user-btn" (click)="openDialog()" mat-fab color="primary" matTooltip="Add user">
  <mat-icon>add</mat-icon>
</button>