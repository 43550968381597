<mat-progress-bar *ngIf="loading" mode="indeterminate" value="40"></mat-progress-bar>
<h1 mat-dialog-title>Edit address</h1>
<div mat-dialog-content class="pg-top">
  <div>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Street + House No.</mat-label>
      <input [disabled]="loading" [(ngModel)]="user.street" matInput placeholder="Placeholder" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="mr-16">
      <mat-label>Zip Code</mat-label>
      <input [disabled]="loading" [(ngModel)]="user.zipCode" matInput placeholder="Placeholder" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>City</mat-label>
      <input [disabled]="loading" [(ngModel)]="user.city" matInput placeholder="Placeholder" />
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button [disabled]="loading" mat-button (click)="dialogRef.close()">Cancel</button>
  <button [disabled]="loading" mat-button (click)="saveUser()" cdkFocusInitial>Save</button>
</div>
