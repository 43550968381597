<mat-card class="user-detail-header">
    <button mat-icon-button class="pin-right-upper-corner" [matMenuTriggerFor]="userMenu">
        <mat-icon>more_vert</mat-icon>
    </button> 
    <mat-menu #userMenu="matMenu">
        <button mat-menu-item (click)="editUserDetail()">Edit</button>
      </mat-menu>
    <img src="assets/img/profile-icon.png" alt="">
    <div class="user-details">
        <h2>{{ user.firstName }} {{ user.lastName}}</h2> <br>
        {{ user.email }}
    </div>
</mat-card>
<mat-card class="mt-32">
    <button mat-icon-button class="pin-right-upper-corner" [matMenuTriggerFor]="addressMenu">
        <mat-icon>more_vert</mat-icon>
    </button> 
    <mat-menu #addressMenu="matMenu">
        <button mat-menu-item (click)="editMenu()">Edit</button>
      </mat-menu>
    <mat-card-title class="address">Address</mat-card-title>
    <mat-card-content>
        {{ user.street }} <br>
        {{ user.zipCode }} {{ user.city }}
    </mat-card-content>
</mat-card>